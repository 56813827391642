import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Helmet from 'react-helmet'
import BuildIcon from '@mui/icons-material/Build'
import { Button } from '@mui/material'
import { isBrowser } from '../utils/utils'
import { getLocalStorageKeys } from '../utils/Storage'
import Statusbar from '../components/generic/Statusbar'
import { useTranslation } from 'react-i18next'

const clearCache = (elements: any) => {
  return new Promise((resolve, reject) => {
    elements.forEach((node: any) => {
      console.log(node)
      localStorage.removeItem(node.key)
    })

    resolve()
  })
}
const DeletePage = (props: any) => {
  const [buttonEnabled, setButtonEnabled] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation('translation')

  const seo = (
    <Helmet>
      <meta content="noindex, follow" name="robots" />
    </Helmet>
  )

  let cacheInformationSafe: any = []
  let cacheInformationOrder: any = []
  let cacheInformationFavorites: any = []
  let cacheInformationCart: any = []

  if (isBrowser() && process.env.ENABLE_LOCALSTORAGE == '1') {
    cacheInformationSafe = getLocalStorageKeys('safe')
    cacheInformationOrder = getLocalStorageKeys('orders')
    cacheInformationFavorites = getLocalStorageKeys('favorites')
    cacheInformationCart = getLocalStorageKeys('cart')
  }

  const isLoading =
    cacheInformationSafe.length +
      cacheInformationOrder.length +
      cacheInformationFavorites.length +
      cacheInformationCart.length ==
    0

  let message = t('Cache geleert')

  const handleClickCache = () => {
    message = t('Cache geleert')
    setButtonEnabled(false)
    setOpen(true)
    clearCache(cacheInformationSafe).then((response) => {
      setButtonEnabled(true)
    })
  }
  const handleClickFavorites = () => {
    message = t('Favoriten entfernt')
    setButtonEnabled(false)
    setOpen(true)
    clearCache(cacheInformationFavorites).then((response) => {
      setButtonEnabled(true)
    })
  }
  const handleClickCart = () => {
    message = t('Warenkorb geleert')

    setButtonEnabled(false)
    setOpen(true)
    clearCache(cacheInformationCart).then((response) => {
      setButtonEnabled(true)
    })
  }
  const handleClickOrders = () => {
    message = t('Auftragsliste geleert')

    setButtonEnabled(false)
    setOpen(true)
    clearCache(cacheInformationOrder).then((response) => {
      setButtonEnabled(true)
    })
  }

  if (isLoading) {
    return (
      <>
        {seo}
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>Browser-Daten löschen</h1>
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        {seo}
        <Statusbar
          duration={1000}
          linkTarget="/delete"
          linkTitle={t('Neuladen')}
          message={message}
          open={open}
          setOpen={setOpen}
        />
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>Browser-Daten löschen</h1>
              <p>
                Sehr wahrscheinlich wurdest du von unserem Kundenservice auf
                diese Seite geleitet, damit du auftretende Probleme bei der
                Benutzung unserer Seite beheben kannst. Es werden
                unterschiedliche Informationen (z.B. Teile unserer Motive und
                Produkte) in deinem Browser zwischengespeichert, damit du
                möglichst schnell und einfach durch unser Sortiment stöbern
                kannst.
              </p>
              <p>
                Bei Problemen kannst du genau dieses Zwischenspeichern in deinem
                Browser-Cache zurücksetzen. Danach werden alle Daten erneut
                geladen und ggf. auftretende Probleme behoben.
              </p>
              <h2>Cache leeren</h2>
              <p>
                Drücke den folgenden Button, um den Cache zu leeren. Wichtige
                Daten werden sofort neugeladen.
              </p>
              <p>
                <Button
                  color="primary"
                  disabled={!buttonEnabled && cacheInformationSafe.length > 0}
                  onClick={handleClickCache}
                  variant="contained"
                >
                  <BuildIcon /> Browser-Cache leeren
                </Button>
              </p>
              <h2>Favoritenliste löschen</h2>
              <p>
                Bei Problemen mit der Favoritenliste kannst du diese hier
                löschen. Bitte beachte, dass die Favoritenliste nur in deinem
                Browser gespeichert ist. Gelöscht ist gelöscht...
              </p>
              <p>
                <Button
                  color="primary"
                  disabled={
                    !buttonEnabled && cacheInformationFavorites.length > 0
                  }
                  onClick={handleClickFavorites}
                  variant="contained"
                >
                  <BuildIcon /> Favoritenliste löschen
                </Button>
              </p>
              <h2>Warenkorb leeren</h2>
              <p>
                Bei Problemen mit dem Warenkorb kannst du diesen hier löschen.
                Bitte beachte, dass der Warenkorb nur in deinem Browser
                gespeichert ist. Gelöscht ist gelöscht...
              </p>
              <p>
                <Button
                  color="primary"
                  disabled={!buttonEnabled && cacheInformationCart.length > 0}
                  onClick={handleClickCart}
                  variant="contained"
                >
                  <BuildIcon /> Warenkorb löschen
                </Button>
              </p>

              <h2>Auftragsliste leeren</h2>
              <p>
                Hier kannst du deine im Browser gespeicherten Aufträge
                entfernen. Bitte beachte, dass durch das Entfernen der Aufträge
                lediglich deine Browserdaten entfernt werden. Die Aufträge sind
                weiterhin in Produktion oder in Auslieferung an dich.
              </p>
              <p>
                <Button
                  color="primary"
                  disabled={!buttonEnabled && cacheInformationCart.length > 0}
                  onClick={handleClickOrders}
                  variant="contained"
                >
                  <BuildIcon /> Auftragsliste leeren
                </Button>
              </p>
            </div>
          </div>
        </div>
        </>
    )
  }
}

export default DeletePage
